import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Image = styled.img`
  float:left;
  width: 130px;
`;

export const Line = styled.div`
  width: 45px;
  height: 0px;

  border: 2px solid #FFFFFF;
  transform: rotate(90deg);
`;

export const LogoName = styled.span`
  float: left;
  font-family: 'Roboto';
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #fff;

  opacity: 0.7;
`;
