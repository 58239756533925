import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Success from '../views/Instalation/Success';
import Error from '../views/Instalation/Error';
import NotFound from '../views/NotFound';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/success" exact component={Success} />
        <Route path="/error" exact component={Error} />
        <Route path='*' exact={true} component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
