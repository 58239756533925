import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
  background: linear-gradient(115.6deg, #38265E 0%, #432360 59.58%, #6d4086 100%);

  padding: 40px 100px;

  svg {
    color: orange;
  }
`;
